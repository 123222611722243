<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="mr-4 mt-2">
          <h1 class="display-2 mt-1 mb-4">
            Campaña 2021/2022
            <v-chip v-if="!isSummaryLoading" color="primary"> Ready </v-chip>
            <v-chip v-if="isSummaryLoading" color="warning"> Loading </v-chip>
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-show="!isSummaryLoading">
          <DenseTableDbView
            :rows="summaryByRegion.rows"
            :columns="summaryByRegion.keys"
          >
          </DenseTableDbView>
          <br />
          <DenseTableDbView
            :rows="summaryByBusinessActivity.rows"
            :columns="summaryByBusinessActivity.keys"
          >
          </DenseTableDbView>
          <br />

          <BasicSimulationForm
            :rows="simulationFactors.rows"
            :columns="simFactorKeys"
            @saveEditRow="simulateFactors"
          ></BasicSimulationForm>
          <BasicSimulationForm
            :rows="forwardPrices.rows"
            :columns="fPricesKeys"
            @saveEditRow="updateForwardPrice"
          ></BasicSimulationForm>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import DenseTableDbView from "../../components/WIP/DenseTableDbView.vue";
import BasicSimulationForm from "../../components/Simulation/BasicSimulationForm.vue";

import { mapGetters } from "vuex";

export default {
  //
  data() {
    return {
      fiz: "baz",
    };
  },
  beforeMount() {
    this.$store.dispatch("getSummaries");
  },
  components: {
    DenseTableDbView,
    BasicSimulationForm,
  },
  methods: {
    simulateFactors(row) {
      let id = row[0];
      let factor = row[3];
      this.$store.dispatch("updateFactor", { id, factor });
    },
    updateForwardPrice(row) {
      let id = row[0];
      let forwardPrice = row[2];
      this.$store.dispatch("updateForwardPrice", { id, forwardPrice });
    },
  },
  computed: {
    ...mapGetters([
      "summaryByRegion",
      "summaryByBusinessActivity",
      "simulationFactors",
      "forwardPrices",
      "isSummaryLoading",
    ]),
    simFactorKeys() {
      return this.simulationFactors.keys.map((d) => {
        return {
          name: d,
          editable: d === "factor" ? true : false,
        };
      });
    },
    fPricesKeys() {
      return this.forwardPrices.keys.map((d) => {
        return {
          name: d,
          editable: d === "forwardPrice" ? true : false,
        };
      });
    },
  },
};
</script>
