<template>
  <div class="pcashflow-holder">
    <v-row>
      <v-col>
        <h3 class="grey--text ml-4 mb-2">{{ tableName }}</h3>
        <table class="pcashflow-table">
          <tr class="pcashflow-table-header">
            <th
              v-for="(col, i) in columns"
              :key="i"
              class="pcashflow-table-header-cell"
            >
              {{ col.name }}
            </th>
          </tr>

          <tr
            class="hoverable-row"
            v-for="(row, i) in rows"
            :key="i"
            @click="rowClick(row, { index: i })"
          >
            <td
              v-for="(value, i) in row"
              :key="i"
              class="pcashflow-table-value-cell"
            >
              {{ value }}
            </td>
          </tr>
        </table>

        <v-dialog v-model="editModal" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> Editar Elemento </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-for="(column, i) in columns" :key="i" cols="6">
                    <span>
                      <v-text-field
                        v-on:keyup.enter="saveEditRow"
                        :filled="column.editable"
                        :label="column.name"
                        :readonly="!column.editable"
                        :ref="'input__' + column"
                        v-model="editItem[i]"
                      >
                      </v-text-field>
                    </span>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates requierror field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent darken-1" text @click="editModal = false">
                Close
              </v-btn>
              <v-btn color="accent darken-1" text @click="saveEditRow">
                <v-icon> mdi-floppy</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.pcashflow-holder {
  width: 100%;
}

.hoverable-row:hover {
  background-color: lightgreen;
}
.pcashflow-table {
  width: 98%;

  margin-left: auto;
  margin-right: auto;
}

.pcashflow-table-header {
  border-bottom: 1px solid rgb(196, 196, 196);
}
table {
  border-collapse: collapse;
}

.pcashflow-table-header-cell {
  width: 75px;
  font-size: 18px;
  text-align: left;
  color: rgb(172, 172, 172);
  font-weight: 300;
}

.pcashflow-table-value-cell {
  width: 80px;
  font-size: 15px;
}
</style>
<script>
export default {
  props: ["rows", "columns", "tableName"],
  methods: {
    rowClick(row, attrs) {
      let index = attrs.index;
      this.selectedItem = index;
      this.editModal = true;
      this.editItem = row;
    },
    saveEditRow() {
      this.$emit("saveEditRow", this.editItem);
      //let id = this.editItem[0];
      // let factor = this.editItem[3];

      this.editModal = false;
      this.editItem = [];
    },
  },
  data() {
    return {
      newModal: false,
      editModal: false,
      newItem: {},
      editItem: {},
      selectedItem: null,
    };
  },
};
</script>
